import { render, staticRenderFns } from "./ReportCard.vue?vue&type=template&id=42a0cffe&scoped=true"
import script from "./ReportCard.vue?vue&type=script&lang=js"
export * from "./ReportCard.vue?vue&type=script&lang=js"
import style0 from "./ReportCard.vue?vue&type=style&index=0&id=42a0cffe&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42a0cffe",
  null
  
)

export default component.exports