<template>
  <b-link :to="btnUrl" style="text-decoration: none;">
    <b-card class="mb-2 reports_cards_main">
      <div slot="header" class="text-center p-2">
          <b-img v-if="imgUrl" fluid :src="imgUrl" :alt="imgAlt" />
          <i v-else class="flaticon import-document stroke text-primary" style="font-size: 48px;font-weight: 200"></i>
      </div>
      <b-card-title class="text-left">
        {{ title }}
      </b-card-title>
      <b-card-text>
        {{ description }}
      </b-card-text>
      <div slot="footer">
        <b-button class="btn btn-sm btn-primary font-weight-bold d-block" :to="btnUrl" variant="primary">
          {{ btnCaption }} <i class="flaticon stroke right-2"></i>
        </b-button>
      </div>
    </b-card>
  </b-link>
</template>

<script>
export default {
  name: 'ReportCard',
  props: ['title', 'description', 'btnCaption', 'btnUrl', 'imgUrl', 'imgAlt']
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/reports-cards.scss';
</style>
