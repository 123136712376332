<template>
  <b-container class="report-account-plan-2 bg-light page-wrapper">
    <div class="main-content-wrapper">
      <b-row class="mb-0 pb-0" no-gutters>
        <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
      </b-row>

      <b-row class="mb-0 pb-0" no-gutters>
        <h1 class="text-left">{{ $t('reports.other.account_plan.budget_title') }}</h1>
      </b-row>
      <account-plan-submenu active="budget"></account-plan-submenu>

      <b-row class="main-content-wrapper pt-0 pb-0 mt-0">
        <b-col lg="4" md="6" sm="12" class="mb-5">
          <report-card
            :title="$t(translationPath + 'multiple_account_budget')"
            :description="$t(translationPath + 'multiple_account_budget_description')"
            :btnCaption="$t(translationPath + 'multiple_account_budget_open')"
            :imgUrl="require('@/assets/img/budget-create.png')"
            :btnUrl="'/budget/accounts/'"
          ></report-card>
        </b-col>

        <b-col lg="4" md="6" sm="12" class="mb-5">
          <report-card
            :title="$t(translationPath + 'single_account_budget')"
            :description="$t(translationPath + 'single_account_budget_description')"
            :btnCaption="$t(translationPath + 'single_account_budget_open')"
            :btnUrl="'/budget/account/'"
            :imgUrl="require('@/assets/img/budget.png')"
            :imgAlt="$t(translationPath + 'budget_form')"
          ></report-card>
        </b-col>

        <b-col lg="4" md="6" sm="12" class="mb-5">
          <report-card
            :title="$t('reports.budget._common.performance_budget')"
            :description="$t('reports.budget._common.performance_budget_description')"
            :btnCaption="$t('reports.budget._common.performance_budget_open')"
            :btnUrl="'/reports/budget/performance/summary'"
            :imgUrl="require('@/assets/img/budget-report.png')"
            :imgAlt="$t('reports.budget._common.performance_budget')"
          ></report-card>
        </b-col>

        <b-col lg="4" md="6" sm="12" class="mb-5">
          <report-card
            :title="$t('reports.budget._common.budget_period_report_title')"
            :description="$t('reports.budget._common.budget_period_report_description')"
            :btnCaption="$t('reports.budget._common.budget_period_report_open')"
            :btnUrl="'/reports/budget/period-report/period'"
            :imgUrl="require('@/assets/img/budget-outcome.png')"
            :imgAlt="$t('reports.budget._common.budget_period_report_title')"
          ></report-card>
        </b-col>

        <b-col lg="4" md="6" sm="12" class="mb-5">
          <report-card
            :title="$t('reports.budget._common.budget_scenarios_title')"
            :description="$t('reports.budget._common.budget_scenarios_description')"
            :btnCaption="$t('reports.budget._common.budget_scenarios_open')"
            :btnUrl="'/settings/chart-of-account/budgets'"
            :imgUrl="require('@/assets/img/budget-scenarios.png')"
            :imgAlt="$t('reports.budget._common.budget_scenarios')"
          ></report-card>
        </b-col>
      </b-row>

    </div><!-- END: main-content-wrapper -->
  </b-container>
</template>

<script>
import AccountPlanSubmenu from './AccountPlanSubmenu'
import { mapState } from 'vuex'
import ReportCard from '@/components/common/ReportCard'

export default {
  name: 'AccountPlanBudget',
  components: { AccountPlanSubmenu, ReportCard },
  data () {
    return {
      translationPath: 'budget._common.'
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    breadcrumbItems () {
      return [
        { text: this.$t('common.header.header_menu.manage_title'), to: '/reports/other/account-plan-v2/incomes' },
        { text: '', active: true }
      ]
    },
    titleMeta () {
      return this.$t('reports.other._common.account_plan_report')
    }
  }
}
</script>
